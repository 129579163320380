
<template>
    <div class="export-email">
        <el-dialog title="导出" :visible.sync="visible"
                   @open="loadLocalEmails" @closed="handleCancel"
        >
            <span>导出至邮箱：</span>
            <el-autocomplete style="width: 60%"
                             v-model="email"
                             :fetch-suggestions="querySearch"
                             placeholder="请输入邮箱地址"
                             :trigger-on-focus="false"
                             @select="handleSelect"
            />
            <p class="tip" v-if="error">{{ error }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取 消</el-button>
                <el-button type="primary" @click="handleConfirm">导 出</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import storage from '../../utils/storage';

const emailReg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
const storageKey = '_zk_input_emails_';

export default {
    props: ['visible'],
    data() {
        return {
            email: '',
            error: '',
            localEmails: []
        };
    },

    watch: {
        visible() {
            this.email = '';
            this.error = '';
        }
    },

    methods: {
        loadLocalEmails() {
            const localEmailsStr = storage.get(storageKey, 'local');
            if(!localEmailsStr) {
                this.localEmails = [];
                return;
            }
            try {
                this.localEmails = JSON.parse(localEmailsStr) || [];
            } catch (e) {
                this.localEmails = [];
            }
        },

        querySearch(qs, cb) {
            // eslint-disable-next-line
            const result = qs ? this.localEmails.filter((item) => item.value.toLowerCase().indexOf(qs.toLowerCase()) === 0) : [];
            cb(result);
        },

        handleSelect(item) {
            this.email = item.value;
        },

        handleCancel() {
            this.$emit('update:visible', false);
        },

        /* eslint-disable */
        handleConfirm() {
            const email = this.email.trim();
            if(!email) {
                this.error = '请输入邮箱';
                return;
            }
            if(!emailReg.test(email)) {
                this.error = '邮箱格式不正确';
                return;
            }

            if(!this.checkValueInArr(email)) {
                this.localEmails.push({ value: email });
                storage.set(storageKey, JSON.stringify(this.localEmails), 'local');
            }

            this.email = '';
            this.error = '';

            this.$emit('email-value', email);
            this.$emit('update:visible', false);
        },
        /* eslint-enable */

        checkValueInArr(text) {
            for(let i = 0; i < this.localEmails.length; i++) {
                if(this.localEmails[i].value === text) {
                    return true;
                }
            }
            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.export-email {
    .tip {
        margin-top: 10px;
        color: red;
        font-size: 12px;
    }
}
</style>
